<template>
    <div class="contenedor-profile d-flex align-items-center justify-content-start flex-column">
            <div class="fondo-degradado-profile" v-if="bulFormulario === false"></div>
            <h1 class="titulo-tickets">Tus gestiones</h1>
            <h2 class="referencias">Referencias: {{referencias}}</h2>
            <div class="col-10">
               <div class="row d-flex flex-row justify-content-around">
                    <div class="col-3 card-estructura" v-for="ticket in tickets" v-bind:key="ticket.id" v-bind:value="ticket">
                        <div class="card-agregar d-flex justify-content-start align-items-center flex-column">
                            <h1 class="titulo-cards">{{ticket.Nombre_ticket}}</h1>
                            <h2 class="numero-tickets">{{ticket.Cantidad}}</h2>
                            <p class="parrafo">{{ticket.Especificacion_1}}</p>
                            <p class="parrafo">{{ticket.Especificacion_2}}</p>
                            <p class="parrafo">{{ticket.Especificacion_3}}</p>
                        </div>
                        <div class="card-botones"></div>
                    </div>
               </div>
           </div>
    </div>
</template>

<script>
    import {mapState} from "vuex"
    import axios from 'axios'

    export default {
        name: "App",
        data(){
            return{
                //control de respuestas de la api
                bul:false,
                bul1:false,
                mensaje:'',

                //informacion formulario
                tickets: this.tickets = {
                    0:{
                        Nombre_ticket:'Ticket mensual',
                        Cantidad:'0',
                        Especificacion_1:'Costo: 2 referencias',
                        Especificacion_2:'Duracion: 1 mes',
                        Especificacion_3:'Plan: basico',
                    }, 
                    1:{
                        Nombre_ticket:'Ticket profesional',
                        Cantidad:'0',
                        Especificacion_1:'Costo: 4 referencias',
                        Especificacion_2:'Duracion: 1 mes',
                        Especificacion_3:'Plan: Todos',
                    },  
                    2:{
                        Nombre_ticket:'Ticket trimestral',
                        Cantidad:'0',
                        Especificacion_1:'Costo: 5 referencias',
                        Especificacion_2:'Duracion: 3 meses',
                        Especificacion_3:'Plan: Basico',
                    },
                    3:{
                        Nombre_ticket:'Ticket dorado',
                        Cantidad:'0',
                        Especificacion_1:'Costo: 10 referencias',
                        Especificacion_2:'Duracion: 3 mes',
                        Especificacion_3:'Plan: Todos',
                    },
                },
                bulFormulario:false,
                referencias: 0
            }
        },

        methods: {

            //actualizar gestiones

            async loadProfile(){
                //consulta de apirest
                let referenciass = await axios.get(`${this.server}/information/referencias`, {

                    params: {token: localStorage.getItem('token')}}, 

                    {headers: {'Content-Type': 'application/json'}
                });

                //token expiro
                if(referenciass.data.message === "Error en validación"){
                    this.$router.push({path: '/app/profile'})
                }

                //cargamos infirmacion de la base de datos
                this.referencias = referenciass.data.referencias

            },

            //funcion encargada de quitar la caja de error

            quitarError(){
                this.bul = false
                this.mensaje = ''
            },

            //funcion encargada de quitar la caja de correcto

            quitarHecho(){
                this.bul1 = false
                this.mensaje = ''
                this.bulLoader = true
                setTimeout(() => {
                    this.loadProfile();
                    this.bulFormulario = false
                    this.bulFormulario2 = 0
                    this.nombreGestion = ''
                    this.nombreGestionEditar = ''
                    this.tipoGestion = ''
                    this.tipoGestionEditar = ''
                    this.id = ''
                    this.palabraConfirmadora = ''
                    this.bulLoader = false
                }, 2000);
            },

            //swift entre error y correcto

            swift(mensaje, caso){
                if(caso === 1){
                    this.bul = true
                    this.mensaje = mensaje
                    setTimeout(() => {
                        this.quitarError()
                    }, 1500);
                }else{
                    this.bul1 = true
                    this.mensaje = mensaje
                    setTimeout(() => {
                        this.quitarHecho()
                    }, 1500);
                }
            }
        },

        created () {
            this.loadProfile();
        },
        computed:{
            ...mapState(["token", "server"])
        }
    }
</script>

<style scoped>
    .referencias{
        color: black;
        text-align: center;
        font-family: Rubik;
        font-size:20px ;
        font-weight:bold ;
        margin-bottom:40px ;
    }
    .loader{
        position: relative;
        width: 200px;
        height: 200px;
        perspective: 300px;
        top:100px;
    }
    .caja{
        position: absolute;
        width: 80%;
        height: 80%;
        background: #110133;
        border-radius:10%;
        left: 10%;
        top: 10%;
        animation: loaderA 3s ease-in-out infinite;
        perspective: 300px;
    }
    .caja2{
        width: 50%;
        height: 50%;
        background: #297F87;
        border-radius:50%;
        margin-left: 25%;
        margin-top: 25%;
        animation: loaderB 3s ease-in-out infinite;
    }

    @keyframes loaderA {
        0%{
            transform: rotateZ(0deg) rotateY(0deg);
            border-radius:10% ;
        }
        50%{
            transform: rotateZ(180deg) rotateY(180deg);
            border-radius:50% ;
        }
        100%{
            transform: rotateZ(360deg) rotateY(360deg);
            border-radius:10% ;
        }
    }

    @keyframes loaderB {
        0%{
            transform: rotateZ(0deg) rotateY(0deg);
            border-radius:50% ;
        }
        50%{
            transform: rotateZ(180deg) rotateY(180deg);
            border-radius:10% ;
        }
        100%{
            transform: rotateZ(360deg) rotateY(360deg);
            border-radius:50% ;
        }
    }

    .form-gestion{
        width: 50%;
    }
    .nombre-gestion{
        color:#297F87;
        margin-left: 5px;
        margin-right:5px;
    }
    .contenedor-agregar-gestion{
        position: absolute;
        top:0px;
        left: 0px;
        width: 100%;
        backdrop-filter:blur(2px);
        background:linear-gradient(240.98deg, rgba(0, 145, 142, 0) 45.71%, #00918E 100%),linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%),linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
    }
    .x-nuevo{
        position: absolute;
        top:0px;
        width: 27.5px;
        height: 27.5px;
        background-repeat: no-repeat;
        background-image: url(../../assets/pictures/X.svg);
        transition: all 0.5s;
        margin-top:135px;
        right: 12.5%;
        z-index: 10;
        cursor: pointer;
    }
    .titulo-agregar-gestion{
        font-family: Rubik;
        font-weight: bold;
        margin-top:40px ;
        font-size: 45px;
        text-align: center;
    }
    .button-gestion{
        width: 50%;
        height: 40px;
        border:none;
        border-radius:10px;
        background: #297F87;
        color: white;
        text-align: center;
        font-family: Rubik;
        font-size:20px ;
        margin-left: 25%;
        margin-top: 80px;
    }
    .label{
        font-family: Rubik;
        margin-top: 75px;   
        font-weight:bold ;
    }
    .error-profile{
        margin: 1.5em 0em 1.5em 0em;
        padding-top: 1em;
        width: 700px;
        background-color: rgb(128, 18, 22,0.75);
        color: white;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
    }
    .correcto-profile{
        margin: 1.5em 0em 1.5em 0em;
        padding-top: 1em;
        width: 700px;
        background-color: rgb(11, 114, 40,0.75);
        color: white;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
    }
    .input{
        border-radius:10px;
        border: #6464646b 2px solid;
        width: 100%;
        padding-left: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
    }
    .contenedor-form{
        position: absolute;
        top:0px;
        left: 10%;
        width: 80%;
        margin-top:100px;
        background: rgba(255, 255, 255, 0.75);
        height: 600px;
        backdrop-filter:blur(2px);
        border-radius: 40px;
    }
    .contenedor-profile{
        position: relative;
        height: auto;
        width: 100%;
        padding-bottom:20px ;
        padding-top:100px ;
    }
    .contenedor-cards{
        position: absolute;
        perspective: 500px;
    }
    .card-estructura, .card-estructura-mas{
        margin-top:35px ;
        position: relative;
        height: 350px;
        perspective: 500px;
    }
    .card-estructura-mas{
        cursor: pointer;
    }
    .card-hover{
        position: absolute;
        background: transparent;
        height: 350px;
        z-index: 10;
        width: 85%;
    }
    .card-hover > div{
        opacity: 0;
        transform: rotateY(180deg) rotateX(-2.5deg) scale(1);
        cursor: pointer;
    }
    .card-botones{
        position: absolute;
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
        border-radius: 16px;
        padding: 10px;
        height: 350px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 9px white;
        cursor: pointer;
        width: 85%;
        opacity: 0;
        transition: all 1s;
        z-index: 20;
    }
    .card-agregar{
        position: absolute;
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.15) 100%);
        border-radius: 16px;
        padding: 10px;
        height: 350px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 9px white;
        width: 85%;
        transition: all 1s;
        z-index: 20;
    }
    .card-hover:hover ~ .card-agregar{
        transform: rotateY(180deg) rotateX(-2.5deg);
        transition: all 1s;
    }
    .card-hover:hover ~ .card-botones{
        transform: rotateY(180deg) rotateX(-2.5deg);
        opacity: 1;
        transition: all 1s;
    }
    .card-hover:hover > div{
        opacity: 1;
        transform: rotateY(0deg) rotateX(-2.5deg);
        transition: all 1s;
    }
    .fondo-degradado-profile{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        background:linear-gradient(290.11deg, rgba(17, 1, 51, 0.26) 0%, rgba(17, 1, 51, 0) 52.11%), linear-gradient(248.49deg, rgba(255, 255, 255, 0) 41.48%, rgba(41,127,135,0.5) 100.24%) ;
        z-index: -10;
        height: calc(100% + 300px);
    }
    .titulo-tickets,.iniciar-gestion,.ultima-actualizacion,.titulo-cards,.numero-tickets{
        font-family: Rubik;
        font-weight: bold;
        margin-top:40px ;
        margin-bottom:15px ;
        font-size: 55px;
        text-align: center;
    }
    .iniciar-gestion,.titulo-cards{
        font-size:40px ;
        margin-top:20px ;
        margin-bottom:0px ;
    }
    .titulo-cards{
        width: 80%;
    }
    .ultima-actualizacion{
        font-size: 18px;
        margin-top:140px ;
        margin-bottom:0px ;
    }
    .numero-tickets{
        font-size: 60px;
        margin-top: 17.5px;
        margin-bottom:17.5px;
    }
    .parrafo{
        margin: 2px;
    }
    .cruz-iniciar{
        width: 60%;
        margin-top:40px ;
    }
    .papelera{
        background-repeat: no-repeat;
        background-image: url(../../assets/pictures/papelera.svg);
        width: 50px;
        height: 50px;
        transition: all 0.5s;
    }
    .flecha{
        background-repeat: no-repeat;
        background-image: url(../../assets/pictures/flecha.svg);
        width: 50px;
        height: 50px;
        margin-top:50px ;
        transition: all 0.5s;
    }
    .lapiz{
        background-repeat: no-repeat;
        background-image: url(../../assets/pictures/lapiz.svg);
        width: 50px;
        height: 50px;
        margin-top:50px ;
        transition: all 0.5s;
    }
</style>